.slick-track {
  max-height: 500px;
}

/* .slick-slide {
    margin: 0 10px;
}
*/

.slick-list {
  height: auto !important;
}

.slick-slider {
  width: 100%;
  /* width: 300px; */
}

.css-co3lyd {
  background-color: #0192CF;
  position: absolute !important;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
}

@media (min-width: 901px) {
  .slick-list {

    max-height: 68vh;
  }
}

@media (max-width: 900px) {
  .slick-slider ul {
    bottom: -35px !important;
  }

  .css-co3lyd {
    background-color: #0192CF;
    position: static !important;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
  }
}

.map svg {
  stroke: #edf0f4;
  max-width: 1200px;
  max-height: 600px;
}

.map svg path {
  fill: #fff;
  cursor: pointer;
  outline: none;
}

.map svg path:hover {
  fill: rgba(0, 149, 218, 0.8);
}

/* .map svg path:focus {
  fill: rgba(0, 149, 218, 0.6);
} */

.map svg path[aria-checked="true"] {
  fill: rgba(0, 149, 218, 0.6);
}

/* .map svg path[aria-current="true"] {
  fill: rgba(56, 43, 168, 0.83);
} */

.font em {
  font-style: normal;
}

@media (min-width: 600px) {
  .css-1yo9slo-MuiToolbar-root {
    min-height: auto !important;
  }
}

.custom-slider {
  margin: 30px auto;
  max-width: "300px";
}

.custom-list {
  margin: 15px auto;
  max-width: 300px;
  overflow-y: scroll;
  height: 70vh;
}

@media (max-width: 1400px) {
  .custom-slider {
    margin: 15px auto;
    max-width: 300px;
  }
}

@media (max-width: 1444px) {
  .custom-list {
    margin: 15px auto;
    max-width: 300px;
    overflow-y: scroll;
    height: 65vh;
  }
}